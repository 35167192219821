import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
// import { ThemeToggler } from 'gatsby-plugin-dark-mode'

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
          }
        }
        markdownRemark {
          frontmatter {
            title
          }
        }
      }
    `}
    render={data => (
        <header className="global-header">
              
                <div className="site-title">
                  <Link to="/">{data.site.siteMetadata.title}</Link>
                </div>
                <div className="heading-right">
            {/*  <ThemeToggler>
          {({ theme, toggleTheme }) => (
            <label className="dark-mode">
              <input
                type="checkbox"
                onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                checked={theme === 'dark'}
              />{' '}
              Dark mode
            </label>
          )}
        </ThemeToggler>
          */}
            <p class="about-link"><Link to="/about">about</Link></p>
            </div>
        </header>
    )}
  />
)
